@import '../../../styles/customMediaQueries.css';

.container {
  background: var(--colorLightBeige);
  min-height: 100vh;
  min-width: 100vw;
}

.content {
  @media (--viewportMedium) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 100vh;
    margin: 0 auto;
    max-width: 1310px;
  }
}

.logo {
  width: 132px;

  @media (--viewportMedium) {
    height: 38px;
    width: 229px;
  }
}

.info {
  padding: 18px 18px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-height: 100vh;
  }
}

.info h1 {
  font-size: 63px;
  font-weight: var(--fontWeightMedium);
  line-height: 62px;
  letter-spacing: -2px;
  margin-bottom: 0;
  margin-top: 42px;
  padding: 0;

  @media (--viewportMedium) {
    font-size: 100px;
    line-height: 98px;
    letter-spacing: -6px;
    margin-top: 60px;
  }

  @media (--viewportLarge) {
    font-size: 136px;
    line-height: 134px;
    letter-spacing: -8.573px;
    margin-top: 108px;
  }
}

.info p {
  font-size: 22px;
  font-weight: var(--fontWeightRegular);
  line-height: 27px;
  margin-bottom: 0;
  margin-top: 24px;

  @media (--viewportMedium) {
    font-size: 30px;
    line-height: 35px;
  }
}

.email {
  font-size: 15px;
  font-weight: var(--fontWeightRegular);
  line-height: 19px;
  margin-bottom: 6px;
  margin-top: 30px;
}

.email a {
  color: var(--colorForeground);
}

.icon {
  background: var(--colorWhite);
  border-radius: 50%;
  display: inline-block;
  margin-right: 18px;
  padding: 12px;
}

.icon svg {
  fill: none;
  stroke: var(--colorForeground);
}

.images {
  overflow: hidden;
  position: relative;
  width: 100%;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-height: fit-content;
  }
}

.images > div {
  display: flex;
  margin-left: -18px;

  @media (--viewportMedium) {
    column-gap: 30px;
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(12, 1fr);
    justify-items: end;
    margin-left: 0;
  }
}

.images img {
  border-radius: var(--borderRadiusLarge);
  margin: 0 4px;
  max-height: 162px;
  max-width: 96px;

  @media (--viewportMedium) {
    max-height: 370px;
    max-width: 218px;
  }
}

.images img:nth-child(1) {
  margin-top: 30px;
  order: 2;

  @media (--viewportMedium) {
    margin-top: 0;
    order: unset;
  }
}

.images img:nth-child(2) {
  order: 4;

  @media (--viewportMedium) {
    order: unset;
  }
}

.images img:nth-child(3) {
  margin-top: 30px;
  order: 5;

  @media (--viewportMedium) {
    margin-top: 0;
    order: unset;
  }
}

.images img:nth-child(4) {
  margin-top: 60px;
  order: 3;

  @media (--viewportMedium) {
    margin-top: 0;
    order: unset;
  }
}

.images img:nth-child(5) {
  margin-top: 60px;
  order: 1;

  @media (--viewportMedium) {
    margin-top: 0;
    order: unset;
  }
}

.images img:nth-child(even) {
  @media (--viewportMedium) {
    margin-bottom: 30px;
  }
}

.images img:nth-child(-1n + 3) {
  grid-row: span 4;
  justify-self: start;
}

.images img:nth-last-child(1) {
  grid-row-start: 2;
  grid-row: 3 / span 4;
}

.images img:nth-last-child(2) {
  grid-row-start: 2;
  grid-row: 7 / span 4;
}
