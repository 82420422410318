@import '../../../styles/customMediaQueries.css';

.container {
  background: linear-gradient(180deg, var(--colorLightBeige) 0%, var(--colorWhite) 100%);
  padding: 0 0 90px;
  z-index: 1;

  @media (--viewportLarge) {
    padding: 0 48px 90px;
  }

  @media (--viewportLargeWithPaddings) {
    padding: 0 96px 90px;
  }
}

.wrapper {
  margin: 50px auto 0;
  max-width: var(--contentMaxWidthLanding);

  @media (--viewportXLarge) {
    max-width: 1320px;
  }
}

.intro {
  @media (--viewportMedium) {
    display: flex;
  }
}

.intro h2 {
  font-size: 42px;
  font-weight: var(--fontWeightRegular);
  line-height: 44px;
  margin: 76px 18px 0;
  padding: 0;

  @media (--viewportMedium) {
    font-size: 65px;
    line-height: 68px;
    margin-bottom: 120px;
    width: 65%;
  }
}

.description {
  font-size: 18px;
  font-weight: var(--fontWeightRegular);
  line-height: 25px;
  margin: 40px 36px 48px 84px;

  @media (--viewportMedium) {
    font-size: 25px;
    line-height: 35px;
    margin-top: 90px;
    width: 35%;
  }
}

.blocks {
  @media (--viewportMedium) {
    display: flex;
    flex-direction: row;
  }
}

.blocks > div {
  cursor: pointer;
  margin-top: 18px;
  transition: all 0.25s ease-in-out;

  @media (--viewportMedium) {
    display: inline-block;
    width: 50%;
  }
}

.blocks .left:hover,
.blocks .right:hover {
  @media (--viewportMedium) {
    width: 60%;
  }
}

.imageContainer {
  height: 480px;
  margin: 0 18px;
  position: relative;

  @media (--viewportLarge) {
    height: 540px;
  }

  &:hover .cta {
    display: flex;
  }
}

.backgroundImage {
  border-radius: var(--borderRadiusExtraLarge);
  height: 100%;
  object-fit: cover;
  pointer-events: none;
  position: absolute;
  width: 100%;
}

.imageContainer h3 {
  color: var(--colorWhite);
  font-size: 38px;
  font-weight: var(--fontWeightMedium);
  line-height: 40px;
  margin-left: 18px;
  margin-top: 48px;
  padding: 0;
  position: absolute;
  width: 284px;

  @media (--viewportLarge) {
    font-size: 58px;
    line-height: 62px;
    margin: 30px;
    width: 436px;
  }
}

.cta {
  align-items: center;
  background: var(--colorForeground);
  border-radius: var(--borderRadiusExtraLarge);
  bottom: 0;
  color: var(--colorWhite);
  display: flex;
  margin: 18px;
  padding: 30px;
  text-decoration: none;
  position: absolute;
  width: calc(100% - 36px);

  @media (--viewportMedium) {
    display: none;
    margin: 24px;
    min-height: 160px;
    width: calc(100% - 48px);
  }
}

.ctaContent {
  width: 80%;
}

.ctaAction {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  width: 20%;
}

.cta h4 {
  font-size: 25px;
  font-weight: var(--fontWeightRegular);
  line-height: 27px;

  @media (--viewportLarge) {
    font-size: 35px;
    line-height: 36px;
  }
}

.cta p {
  font-size: 18px;
  font-weight: var(--fontWeightRegular);
  line-height: 23px;

  @media (--viewportMedium) {
    font-size: 20px;
    line-height: 25px;
    width: 300px;
  }

  @media (--viewportLarge) {
    width: 320px;
  }

  @media only screen and (min-width: 1440px) {
    width: 460px;
  }
}

.cta .iconContainer {
  align-items: center;
  background: var(--colorLightBeige);
  border-radius: 50%;
  display: flex;
  height: 48px;
  justify-content: center;
  width: 48px;
}

.cta svg {
  fill: none;
  stroke: var(--colorForeground);
}
