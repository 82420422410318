@import '../../../styles/customMediaQueries.css';

:root {
  --overlayHeight: 114px;
  --headingOffset: 12px;
}

.container {
  height: calc(175dvh - var(--topbarHeight));
  overflow: hidden;
  position: relative;
  width: 100vw;

  @media (--viewportLarge) {
    height: calc(200svh - var(--topbarHeightDesktop));
  }
}

.wrapper {
  height: 100%;
  position: relative;
  top: 0;
}

.center {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: calc(100dvh - var(--topbarHeight));
  justify-content: center;
  overflow: hidden;
  position: fixed;
  top: var(--topbarHeight);
  width: 100vw;

  @media (--viewportMedium) {
    height: calc(100svh - var(--topbarHeightDesktop));
    top: var(--topbarHeightDesktop);
  }
}

.centerUnfixed {
  border-radius: 0 0 var(--borderRadiusLarge) var(--borderRadiusLarge);
  height: 100dvh;
  position: relative;
  top: calc(75dvh - var(--topbarHeight));
  width: 100%;

  @media (--viewportLarge) {
    top: calc(100svh - var(--topbarHeightDesktop));
  }
}

.center video {
  object-fit: cover;
  width: 100vw;
  z-index: -1;
}

h1.headerA,
h1.headerB {
  bottom: 0;
  color: var(--colorForeground);
  font-size: 50px;
  font-weight: var(--fontWeightMedium);
  height: 50px;
  line-height: 50px;
  margin: auto;
  padding: 0;
  position: absolute;
  top: 0;
  transition: all 0.5s ease-in-out, color 0.25s ease-in-out 0.25s;
  z-index: 1;

  @media (--viewportMedium) {
    font-size: 60px;
    line-height: 60px;
    height: 60px;
  }

  @media (--viewportLarge) {
    font-size: 72px;
    line-height: 72px;
    height: 72px;
  }

  @media (--viewportLargePlus) {
    font-size: 96px;
    line-height: 96px;
    height: 96px;
  }

  @media (--viewportXLarge) {
    font-size: 120px;
    line-height: 120px;
    height: 120px;
  }
}

h1.headerA {
  bottom: 50%;

  @media (--viewportLarge) {
    bottom: var(--headingOffset);
    left: 0;
    padding-right: 132px;
    text-align: right;
    width: 50%;
  }
}

h1.headerB {
  top: 50%;

  @media (--viewportLarge) {
    bottom: var(--headingOffset);
    padding-left: 132px;
    right: 0;
    text-align: left;
    top: 0;
    width: 50%;
  }
}

h1.headerAScrolled {
  bottom: 10%;
  color: var(--colorWhite);

  @media (--viewportLarge) {
    bottom: var(--headingOffset);
    padding-right: 10px;
  }
}

h1.headerBScrolled {
  top: 10%;
  color: var(--colorWhite);

  @media (--viewportLarge) {
    padding-left: 10px;
    top: 0;
  }
}

.logo {
  fill: var(--colorLightBeige);
  margin: auto;
  position: absolute;
  z-index: 0;
}

.description {
  align-items: center;
  background: var(--colorSecondary);
  display: flex;
  justify-content: center;
  height: calc(100dvh - var(--topbarHeight) - var(--overlayHeight));
  max-width: 100vw;
  position: fixed;
  scroll-behavior: smooth;
  visibility: hidden;
  width: 100vw;
  z-index: 1;

  @media (--viewportLarge) {
    height: calc(100dvh - var(--topbarHeightDesktop) - var(--overlayHeight));
  }
}

.descriptionVisible {
  visibility: visible;
}

.descriptionUnfixed {
  position: absolute;
  /* top: var(--topbarHeight) !important; */

  @media (--viewportLarge) {
    top: var(--topbarHeightDesktop) !important;
  }
}

.description .arrow {
  fill: var(--colorWhite);
  height: 40px;
  margin: 0 24px;
  width: 40px;

  @media (--viewportLarge) {
    height: 90px;
    left: 30px;
    margin: 0;
    position: absolute;
    top: 0;
    width: 90px;
  }
}

.description > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.description p {
  color: var(--colorWhite);
  font-size: 26px;
  font-weight: var(--fontWeightMedium);
  line-height: 30px;
  margin: 18px 24px 18px;
  padding: 0;

  @media (--viewportMedium) {
    max-width: 640px;
  }

  @media (--viewportLarge) {
    font-size: 48px;
    line-height: 60px;
    margin: 18px 150px 18px;
    max-width: 1280px;
  }
}

.ctaOverlay {
  background: var(--colorSecondary);
  bottom: calc(-1 * var(--overlayHeight));
  height: var(--overlayHeight);
  padding: 18px 12px;
  position: fixed;
  transition: bottom 0.25s ease-in-out;
  width: 100vw;
  z-index: 1;

  @media (--viewportLarge) {
    align-items: center;
    display: flex;
    justify-content: center;
  }
}

.ctaOverlayVisible {
  bottom: 0;
}

.ctaOverlayUnfixed {
  border-radius: 0 0 var(--borderRadiusLarge) var(--borderRadiusLarge);
  position: absolute;
}

.ctaOverlay > p {
  color: var(--colorWhite);
  font-size: 18px;
  font-weight: var(--fontWeightMedium);
  line-height: 22px;
  margin-top: 0;
  padding: 0;
  text-align: center;

  @media (--viewportLarge) {
    font-size: 25px;
    line-height: 25px;
    margin-bottom: 0;
  }
}

.ctaLinks {
  display: flex;
  justify-content: center;

  @media (--viewportLarge) {
    margin-left: 66px;
  }
}

.ctaLinks a {
  align-items: center;
  border-radius: 0.5rem;
  display: flex;
  font-size: 16px;
  font-weight: var(--fontWeightMedium);
  line-height: 18px;
  padding: 6px 12px;
  text-decoration: none;

  @media (--viewportLarge) {
    padding: 18px;
  }
}

.ctaLinks a svg {
  fill: none;
}

.ctaLinks a:first-child {
  background: var(--marketplaceColor);
  color: var(--colorWhite);
  margin-right: 6px;

  &:hover {
    background: var(--marketplaceColorDark);
  }
}

.ctaLinks a:first-child svg {
  margin-left: 6px;
  stroke: var(--colorWhite);
}

.ctaLinks a:last-child {
  background: var(--colorWhite);
  color: var(--colorForeground);
  margin-left: 6px;

  &:hover {
    background: var(--colorLightBeige);
  }
}

.ctaLinks a:last-child svg {
  margin-left: 6px;
  stroke: var(--colorForeground);
}
